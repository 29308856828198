import React from "react";
import { Container, Link, Box, Button } from "@material-ui/core";

// Returns a page when the driver has completed their route
export function RouteComplete({ id, setIndex }) {
  // Text to show driver
  return (
    <Container maxWidth="sm" style={{ textAlign: "left" }}>
      <h1>
        Jazakallah! <br />
        Route {id} Completed.
      </h1>
      <h3>Thank you for volunteering to deliver Niyaz.</h3>
      <p>
        This route was custom built for ISIJ. If you would like to make your own
        custom routes with an app for your deliveries, please contact us at{" "}
        <Link href="mailto:akeel.hasham@gmail.com">akeel.hasham@gmail.com</Link>
      </p>
      <Box m={0}>
        <Button
          fullWidth
          onClick={() => {
            setIndex(0);
          }}
          variant="contained"
          color="primary"
        >
          Return to Route
        </Button>
      </Box>
    </Container>
  );
}

export default RouteComplete;
