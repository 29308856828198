import React, { useState } from "react";
import { Box, TextField, Button, Link, Container } from "@material-ui/core";

// Page with a TextField that allow users to access a specific route
export function RouteLoader() {
  const [route, setRoute] = useState("");
  return (
    // Layout
    <Container maxWidth="sm">
      <Box m={1}>
        {/* Field for Route Number */}
        <TextField
          name="number"
          label="Route Number"
          value={route}
          onChange={(event, value) => {
            setRoute(event.target.value);
          }}
          variant="outlined"
          fullWidth
        />
      </Box>
      <Box m={1}>
        {/* Submit Button */}
        <Link
          href={"/routes/" + route}
          key="signup"
          style={{ textDecoration: "none" }}
        >
          <Button variant="contained" color="primary" fullWidth>
            Go
          </Button>
        </Link>
      </Box>
    </Container>
  );
}

export default RouteLoader;
