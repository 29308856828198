import React from "react";
import {
  makeStyles,
  Container,
  Box,
  TextField,
  Button,
} from "@material-ui/core";
import { useForm } from "react-hook-form";
import { Alert } from "@material-ui/lab";

// Styling
const useStyles = makeStyles((theme) => ({
  textField: {
    marginBottom: theme.spacing(1),
  },
  buttonGreen: {
    backgroundColor: "#198754",
    "&:hover": {
      backgroundColor: "#125F3B",
    },
    marginBottom: theme.spacing(1),
  },
  buttonGrey: {
    backgroundColor: "#495057",
    "&:hover": {
      backgroundColor: "#393E44",
    },
    marginBottom: theme.spacing(1),
  },
}));

// Returns a Landing page to start a route
export function LandingPage({ id, data, setIndex, name, setName }) {
  const { register, handleSubmit, errors } = useForm();
  const classes = useStyles();

  const onStart = (data) => {
    var fetchStr = process.env.REACT_APP_API_URL + "drivers/" + id;
    fetch(fetchStr, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ name: name, completed: false }),
    });
    setIndex(1);
  };

  const onComplete = () => {
    var fetchStr = "/drivers/" + id;
    fetch(fetchStr, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ name: name, completed: true }),
    });
    setIndex(data.length + 1);
  };

  return (
    <>
      {/* Welcome text */}
      <Container maxWidth="sm" style={{ textAlign: "left" }}>
        <h1>Welcome Route {id} Driver</h1>
        <b>Deliveries: {data.length}</b>
        <p>
          Salaam Alaykum,
          <br />
          Thank you for volunteering for Niyaz delivery. Please follow the
          instructions below:
        </p>
        <p>
          <b>House Deliveries:</b> Place the niyaz on the doorstep and ring the
          doorbell.
        </p>
        <p>
          <b>Apartment Deliveries:</b> Call the recipient before you arrive.
          Place the delivery at the building entrance for the recipient to
          pickup.
        </p>
        <p>
          Ensure you are delivering the correct number of portions to each
          delivery. If they are any duplicates, please deliver them as we have
          already checked the data.
        </p>
        {errors.name && (
          <Alert variant="filled" severity="error">
            {errors.name.message}
          </Alert>
        )}
        <p>Please enter your full name before proceeding:</p>
      </Container>
      {/* Name field */}
      <Container maxWidth="sm">
        <Box m={0}>
          <TextField
            inputRef={register({
              required: {
                value: true,
                message: "You must enter your full name to proceed",
              },
              minLength: {
                value: 3,
                message: "Your name must be at least 3 characters to proceed",
              },
              pattern: {
                value: /.*\s.*/,
                message: "You must enter your first and last name to proceed",
              },
            })}
            name="name"
            label="Full Name"
            className={classes.textField}
            onChange={(event) => {
              setName(event.target.value);
            }}
            variant="outlined"
            fullWidth
          />
        </Box>
        {/* Start Route button */}
        <Box m={0}>
          <Button
            fullWidth
            onClick={handleSubmit(onStart)}
            className={classes.buttonGreen}
            variant="contained"
            color="primary"
          >
            Start Route
          </Button>
        </Box>
        {/* Route complete button */}
        <Box m={0}>
          <Button
            fullWidth
            className={classes.buttonGrey}
            onClick={handleSubmit(onComplete)}
            variant="contained"
            color="primary"
          >
            Route Complete
          </Button>
        </Box>
      </Container>
    </>
  );
}

export default LandingPage;
