import React from "react";
import { Link } from "react-router-dom";
import HomeIcon from "@material-ui/icons/Home";
import { AppBar, Toolbar, List, makeStyles } from "@material-ui/core";

// Styling
const useStyles = makeStyles((theme) => ({
  navDisplayFlex: {
    display: `flex`,
    justifyContent: `space-between`,
  },
  linkText: {
    textDecoration: `none`,
    textTransform: `uppercase`,
    color: `white`,
  },
}));

// Returns a NavBar with a Home button
function NavBar() {
  // Styling
  const classes = useStyles();
  return (
    <AppBar position="static" style={{ alignItems: "center" }}>
      <Toolbar>
        <List
          className={classes.navDisplayFlex}
          component="nav"
          aria-labelledby="main navigation"
        >
          {/* Home Button */}
          <Link to="/routes/" key="routes" className={classes.linkText}>
            <HomeIcon />
          </Link>
        </List>
      </Toolbar>
    </AppBar>
  );
}
export default NavBar;
