import React, { useState, useEffect } from "react";
import { Container, makeStyles, CircularProgress } from "@material-ui/core";
import DeliveryPage from "./DeliveryPage";
import LandingPage from "./LandingPage";
import RouteComplete from "./RouteComplete";

// Component for the entire route
export function DriverRoute({ match }) {
  const { id } = match.params;
  const [data, setData] = useState(null);
  const [index, setIndex] = useState(0);
  const [name, setName] = useState("");

  // API call to load the route
  // update firebase
  useEffect(() => {
    console.log(process.env.REACT_APP_API_URL);
    var fetchStr = process.env.REACT_APP_API_URL + "routes/" + id;
    fetch(fetchStr, {
      method: "GET",
    })
      .then((response) => response.json())
      .then(setData);
  }, []);

  // Return the right component based on the point in the route
  if (data) {
    if (index == 0) {
      return (
        <LandingPage
          id={id}
          data={data}
          setIndex={setIndex}
          name={name}
          setName={setName}
        />
      );
    } else if (index > data.length) {
      return <RouteComplete id={id} setIndex={setIndex} />;
    } else {
      return (
        <DeliveryPage
          data={data}
          index={index}
          setIndex={setIndex}
          name={name}
          id={id}
        />
      );
    }
  }

  // Loading bar
  return (
    <Container>
      <CircularProgress />
    </Container>
  );
}

export default DriverRoute;
