import React from "react";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { Link, Button, Container, Box, makeStyles } from "@material-ui/core";
import { Pagination } from "@material-ui/lab";

// Styling
const useStyles = makeStyles((theme) => ({
  pagination: {
    [theme.breakpoints.down("sm")]: {
      height: "26px",
      margin: "0px 1px",
      padding: "0px 4px",
      minWidth: "26px",
    },
  },
  buttonGreen: {
    backgroundColor: "#198754",
    "&:hover": {
      backgroundColor: "#125F3B",
    },
    marginBottom: theme.spacing(1),
  },
  buttonGrey: {
    backgroundColor: "#495057",
    "&:hover": {
      backgroundColor: "#393E44",
    },
    marginBottom: theme.spacing(1),
  },
}));

// Returns a pagination component for the deliveries
function DeliveryPagination({ index, setIndex, data }) {
  // Change size of pagination based on screen size
  let size = "medium";
  if (useMediaQuery("(max-width:355px)")) {
    size = "small";
  }
  if (useMediaQuery("(min-width:430px)")) {
    size = "large";
  }
  //Styling
  const classes = useStyles();
  // Return pagination component as footer
  return (
    <div className="footer">
      <Container style={{ width: "fit-content", padding: "0" }}>
        <h3 style={{ marginBlock: "0.5em" }}>Deliveries</h3>
        <Pagination
          className={classes.pagination}
          size={size}
          variant="outlined"
          shape="rounded"
          page={index}
          onChange={(event, value) => {
            setIndex(value);
          }}
          count={data.length}
        />
      </Container>
    </div>
  );
}

// Returns a Page for a specific route with Pagination for each delivery
export function DeliveryPage({ data, index, setIndex, id, name }) {
  // Styling
  const classes = useStyles();
  return (
    <>
      <Container maxWidth="sm" m={2} style={{ textAlign: "left" }}>
        {/* Heading */}
        <h1 style={{ marginBlock: "0.5em" }}>
          Delivery {index} of {data.length}
        </h1>
        {/* Content */}
        <p style={{ width: "fit-content", lineHeight: "1.5" }}>
          <b>ID:</b> {data[index - 1].id}
          <br />
          <b>Address: </b>
          <Link
            target="_blank"
            rel="noopener"
            href={
              "https://www.google.com/maps/dir/?api=1&destination=" +
              data[index - 1].street_number +
              "+" +
              data[index - 1].street_name +
              "+" +
              data[index - 1].postal_code
            }
            key="signup"
          >
            {data[index - 1].street_number} {data[index - 1].street_name},{" "}
            {data[index - 1].city}, {data[index - 1].postal_code}
          </Link>
          {/* Only show apartment number if there is one */}
          {data[index - 1].unit_number && (
            <>
              <br />
              <b> Apartment/Unit:</b> {data[index - 1].unit_number}
            </>
          )}
          <br />
          <b>Phone Number:</b>{" "}
          <Link href={"tel:" + data[index - 1].phone_number}>
            {data[index - 1].phone_number}
          </Link>
          {/* <b>Portions:</b> {data.deliveries[index - 1].portions} */}
          {/*  Only show notes if they've been provided */}
          {data[index - 1].notes && (
            <>
              <br />
              <b>Notes:</b> {data[index - 1].notes}
            </>
          )}
        </p>
        <p>
          <b>If you require assistance please message in the WhatsApp group</b>
        </p>
        {/* Call Recipient Button */}
        <Box m={0} style={{ paddingBottom: "8px", paddingTop: "6px" }}>
          <Link
            href={"tel:" + data[index - 1].phone_number}
            key="signup"
            style={{ textDecoration: "none" }}
          >
            <Button
              fullWidth
              className={classes.buttonGreen}
              variant="contained"
              color="primary"
            >
              Call Recipient
            </Button>
          </Link>
        </Box>
        {/* Get Directions Button */}
        <Box m={0}>
          <Link
            href={
              "https://www.google.com/maps/dir/?api=1&destination=" +
              data[index - 1].street_number +
              "+" +
              data[index - 1].street_name +
              "+" +
              data[index - 1].postal_code
            }
            target="_blank"
            rel="noopener"
            key="forgotPassword"
            style={{ textDecoration: "none" }}
          >
            <Button
              fullWidth
              className={classes.buttonGrey}
              variant="contained"
              color="primary"
            >
              Get Directions
            </Button>
          </Link>
        </Box>
        {index == data.length && (
          <Box style={{ textAlign: "center" }}>
            <p>
              <b>This is your last delivery</b>
            </p>
            <Button
              onClick={() => {
                var fetchStr = process.env.REACT_APP_API_URL + "drivers/" + id;
                fetch(fetchStr, {
                  method: "POST",
                  headers: { "Content-Type": "application/json" },
                  body: JSON.stringify({ name: name, completed: true }),
                });
                setIndex(data.length + 1);
              }}
              variant="contained"
              color="primary"
              fullWidth
            >
              Route Complete
            </Button>
          </Box>
        )}
      </Container>
      <DeliveryPagination index={index} setIndex={setIndex} data={data} />
    </>
  );
}

export default DeliveryPage;
