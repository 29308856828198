import "./App.css";
import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { DriverRoute } from "./pages/DriverRoute";
import { RouteLoader } from "./pages/RouteLoader";
import NavBar from "./pages/NavBar";

function App() {
  return (
    // React Routing
    <Router>
      <div className="App">
        <NavBar />
        <Switch>
          <Route exact path="/routes" component={RouteLoader} />
          <Route path="/routes/:id" component={DriverRoute} />
          <Route component={RouteLoader} />
        </Switch>
      </div>
    </Router>
  );
}

export default App;
